<template>
  <a-modal v-model="visible" :title="title" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click.prevent.stop="submit">
        {{ !this.isCreate ? 'Изменить' : 'Добавить' }}
      </a-button>
    </template>
    <a-form-model v-if="isLoaded" ref="form" :model="form" :rules="rules">
      <a-form-model-item ref="active" label="Статус" prop="active">
        <a-select v-model="form.active">
          <a-select-option v-for="status in statuses" :key="status.id" :value="status.id">
            {{ status.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.position !== undefined" label="Позиция" ref="position" prop="position">
        <a-input v-model="form.position"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.ru !== undefined" label="Название(ru)" ref="ru" prop="ru">
        <a-input v-model="form.ru"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.ua !== undefined" label="Название(ua)" ref="ua" prop="ua">
        <a-input v-model="form.ua"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.value !== undefined" label="Значение" ref="value" prop="value">
        <a-input v-model="form.value"/>
      </a-form-model-item>

      <a-form-model-item v-if="path === 'currency'" label="Название" ref="name" prop="name">
        <a-input v-model="form.name"/>
      </a-form-model-item>

      <a-form-model-item v-if="path === 'currency'" label="Символ" ref="symbol" prop="symbol">
        <a-input v-model="form.symbol"/>
      </a-form-model-item>

      <a-form-model-item v-if="path === 'operation_type'" label="Алиас" ref="alias" prop="alias">
        <a-input v-model="form.alias"/>
      </a-form-model-item>

      <a-form-model-item v-if="path === 'city'" label="Регион" ref="region_id" prop="region_id">
        <a-select v-model="form.region_id" placeholder="Выберите регион">
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="path === 'district' || path === 'station'" label="Город" ref="city_id" prop="city_id">
        <a-select v-model="form.city_id" placeholder="Выберите город">
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="showCategorySelect" label="Категория" ref="category_id" prop="category_id">
        <a-select v-model="form.category_id" placeholder="Выберите категорию">
          <a-select-option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if="form.image !== undefined">
        <a-upload
          list-type="picture-card"
          :file-list="fileList"
          @change="handleChange"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import apiClient from '@/services/axios'
import { activityStatuses } from '@/utils'

const VALIDATION_REGEX = /[^А-Яа-яІіA-Za-z\s]+/

export default {
  name: 'EditAddTypeModal',

  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      statuses: activityStatuses,
      visible: false,
      search: '',
      isCreate: false,
      isLoaded: false,
      fileList: [],
      previewVisible: false,
      previewImage: '',
      id: '',
      form: {
        active: '',
        ru: undefined,
        ua: undefined,
        value: undefined,
        image: undefined,
        position: undefined,
      },
      isValid: false,
      rules: {
        active: [
          {
            required: true,
            message: 'Пожалуйста введите статус',
            trigger: 'blur',
          },
        ],
      },
      regions: [],
      cities: [],
      categories: [],
    }
  },

  watch: {
    'form.ru'() {
      this.form.ru = this.form.ru?.replace(VALIDATION_REGEX, '')
    },
    'form.ua'() {
      this.form.ua = this.form.ua?.replace(VALIDATION_REGEX, '')
    },
  },

  computed: {
    showCategorySelect() {
      return this.path === 'life_type' || this.path === 'heating' || this.path === 'layout' || this.path === 'apartment' || this.path === 'type_wall' || this.path === 'realty_type'
    },
  },

  methods: {
    async getSelects() {
      if (this.path === 'city') {
        this.regions = (await apiClient.get('admin/region', { params: { per_page: 10000 } })).data.data.values.data
      }
      if (this.path === 'district' || this.path === 'station') {
        this.cities = (await apiClient.get('admin/city', { params: { per_page: 10000 } })).data.data.values.data
      }
      if (this.showCategorySelect) {
        this.categories = (await apiClient.get('admin/category', { params: { per_page: 10000 } })).data.data.values.data
      }
    },

    handleChange({ fileList }) {
      this.fileList = fileList
    },

    handleSearch(value) {
      this.search = value
    },

    openCreate(item = null) {
      this.getSelects()

      if (item) {
        this.form.active = ''

        if (item.position !== undefined) {
          this.form.position = ''
          this.rules.position = [
            {
              required: true,
              message: 'Пожалуйста введите позицию',
              trigger: 'blur',
            },
          ]
        }

        if (item.value) {
          this.rules.value = [
            {
              required: true,
              message: 'Пожалуйста введите значение',
              trigger: 'blur',
            },
          ]
          this.form.value = ''
        }

        if (item.icon !== undefined) this.form.image = ''

        if (item.translations) {
          this.rules.ru = [
            {
              required: true,
              message: 'Пожалуйста введите название',
              trigger: 'blur',
            },
          ]
          this.rules.ua = [
            {
              required: true,
              message: 'Пожалуйста введите название',
              trigger: 'blur',
            },
          ]
          this.form.ru = ''
          this.form.ua = ''
        }

        if (item.region_id) {
          this.rules.region_id = [
            {
              required: true,
              message: 'Пожалуйста выберите регион',
              trigger: 'blur',
            },
          ]
        }

        if (item.category_id) {
          this.rules.category_id = [
            {
              required: true,
              message: 'Пожалуйста выберите категорию',
              trigger: 'blur',
            },
          ]
        }

        if (item.city_id) {
          this.rules.city_id = [
            {
              required: true,
              message: 'Пожалуйста выберите город',
              trigger: 'blur',
            },
          ]
        }

        if (item.symbol) {
          this.rules.symbol = [
            {
              required: true,
              message: 'Пожалуйста введите значение',
              trigger: 'blur',
            },
          ]
        }

        if (item.alias) {
          this.rules.alias = [
            {
              required: true,
              message: 'Пожалуйста введите алиас',
              trigger: 'blur',
            },
          ]
        }
      }

      this.visible = true
      this.isCreate = true
      this.isLoaded = true
    },

    open(item = null) {
      this.getSelects()

      if (item) {
        this.id = item.id
        this.form.active = item.active

        if (item.position !== undefined) {
          this.form.position = item.position
          this.rules.position = [
            {
              required: true,
              message: 'Пожалуйста введите позицию',
              trigger: 'blur',
            },
          ]
        }

        if (item.icon !== undefined) {
          if (item.icon) {
            this.fileList.push({
              url: item.icon,
              uid: '-4',
              name: 'image.png',
              status: 'done',
            })
          }
          this.form.image = item.icon
        }

        if (item.value) {
          this.rules.value = [
            {
              required: true,
              message: 'Пожалуйста введите значение',
              trigger: 'blur',
            },
          ]
          this.form.value = item.value
        }

        if (item.translations) {
          this.rules.ru = [
            {
              required: true,
              message: 'Пожалуйста введите название',
              trigger: 'blur',
            },
          ]
          this.rules.ua = [
            {
              required: true,
              message: 'Пожалуйста введите название',
              trigger: 'blur',
            },
          ]

          if (item.translations[0].locale === 'ru') {
            this.form.ru = item.translations[0].name
            this.form.ua = item.translations[1].name
          } else {
            this.form.ru = item.translations[1].name
            this.form.ua = item.translations[0].name
          }
        }

        if (item.region_id) {
          this.$set(this.form, 'region_id', item.region_id)
        }

        if (item.city_id) {
          this.$set(this.form, 'city_id', item.city_id)
        }

        if (item.category_id) {
          this.$set(this.form, 'category_id', item.category_id)
        }

        if (item.symbol) {
          this.$set(this.form, 'symbol', item.symbol)
        }

        if (item.name) {
          this.$set(this.form, 'name', item.name)
        }

        if (item.alias) {
          this.$set(this.form, 'alias', item.alias)
        }
      }

      this.visible = true
      this.isLoaded = true
    },

    async submit() {
      this.$refs.form.validate(valid => {
        this.isValid = valid
      })

      if (!this.isValid) return
      let image

      if (this.fileList.length) {
        image = this.fileList[0].thumbUrl ? this.fileList[0].thumbUrl : this.fileList[0].url
      }

      this.isCreate ? this.$emit('create', this.form, image) : this.$emit('edit', this.form, this.id, image)
    },

    close() {
      this.visible = false
      this.id = ''
      this.search = ''
      this.form = {
        active: '',
        ru: undefined,
        ua: undefined,
        value: undefined,
        position: undefined,
        image: undefined,
      }
      this.fileList = []
      this.isCreate = false
      this.rules = {
        active: [
          {
            required: true,
            message: 'Пожалуйста введите статус',
            trigger: 'blur',
          },
        ],
      }
      this.isLoaded = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-form-item-label {
  height: fit-content;
  line-height: 120%;
  label {
    height: fit-content;
  }
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}
</style>
